* {
  box-sizing: border-box;
}

body {
  background: #f4f2ea;
  margin: 0;
  padding: 0;
  font-family: "MaisonNeue", "Segoe UI", "Helvetica Neue", -apple-system,
    system-ui, BlinkMacSystemFont, Roboto, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

body, p, div {
  font-size: 12px;
  color: #333;
}

/* Search Bar */
#searchBar {
  width: 60%;
}

.hidden { display: none !important }

#searchBarInner {
  position: relative;
  width: 100%;
}

#searchInput {
  font-size: 1em;
  width:100%;
  padding: 5px;
}

#searchResults {
  position: absolute;
  display: block;
  width: 100%;
  z-index: 99;
  top: 25px;
  left: 0;
  text-align: left;
  transition: opacity .25s;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #999;
  display: inherit;
}

.searchResultItem {
  padding: 2px 0;
}

/* Header */

.headerwrap {
  background-color: #ccc;
  background-image: -moz-linear-gradient(left, #777675, #828178);
}

.header {
  height: 44px;
  max-width: 1024px;
  min-width: 360px;
  background-color: #fff;
  margin: 0 auto;
  border-bottom: 1px;
  border-bottom: 1px solid #ccc;
}

.headerInnerGrid {
  max-width: 664px;
  display: grid;
  grid-template-columns: 44px 1fr 100px;
  justify-items: center;
  align-items: center;
  height: 44px;
}

.headerInner {
  max-width: 664px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 44px;
}

#headerIcon {
  margin-left: 5px;
}

#headerViewAll {
  white-space: nowrap;
  padding-right: 5px;
  font-size: 1em;
}

.contentwrapfull {
  max-width: 1024px;
  min-width: 360px;
  margin: 10px auto;
}

.contentwrap {
  display: block;
}
/*
.contentwrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "content"
    "side";
  grid-gap: 0px;
  max-width: 1024px;
  min-width: 360px;
  margin: 10px auto;
}
*/

div.content {
  grid-area: content;
  background-color: #fff;
  border-left: 1px solid #ccc;
  max-width: 1024px;
  padding-bottom: 20px;
}

div.side {
  grid-area: side;
  background-color: #fff;
  border-left: 1px solid #ccc;
  display: none;
}

.footerwrap {
  border-top: 1px solid #ccc;
  width: 100%;
  background-color: #777675;
}

.footer {
  max-width: 1024px;
  min-width: 360px;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  border-bottom: 1px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  flex-wrap: wrap;
}

.footer a {
  color: #fff;
}

.slogan {
  color: #f8b32f;
  font-size: 1.1em;
  font-family: "Oswald", arial, helvetica, sans-serif;
  flex: 1;
  white-space: nowrap;
}
.copyright {
  color: #fff;
  flex: 1;
  white-space: nowrap;
}
.footerLinks {
  color: #fff;
  flex: 1;
  white-space: nowrap;
}

.responsive {
  height: 100%;
  width: auto;
}

img {
  border-width: 0;
}

h1 {
  font-weight: normal;
  font-size: 1.2em;
  margin-top: 0;
  font-family: "Oswald", arial, helvetica, sans-serif;
  padding-bottom: 1px;
  background: #ccc;
  color: #fff;
  margin-left: -17px;
  margin-bottom: 0px;
  padding-left: 25px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ccc),
    to(#bbb)
  );
  /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(left, #ccc, #bbb);
  /* Chrome 10+, Saf5.1+ */
  background-image: -moz-linear-gradient(left, #ccc, #bbb);
  /* FF3.6 */
  background-image: -ms-linear-gradient(left, #ccc, #bbb);
  /* IE10 */
  background-image: -o-linear-gradient(left, #ccc, #bbb);
  /* Opera 11.10+ */
  background-image: linear-gradient(to right, #ccc, #bbb);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#cccccc', EndColorStr='#bbbbbb');
  /* IE6ÐIE9 */
}

.h1corner {
  left: -17px;
  top: -1px;
  position: relative;
  float: left;
}

h1.top {
  background-color: #f8b32f;
  color: #fff !important;
  font-size: 1.2em;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f8b32f),
    to(#eaa92c)
  );
  /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(left, #f8b32f, #eaa92c);
  /* Chrome 10+, Saf5.1+ */
  background-image: -moz-linear-gradient(left, #f8b32f, #eaa92c);
  /* FF3.6 */
  background-image: -ms-linear-gradient(left, #f8b32f, #eaa92c);
  /* IE10 */
  background-image: -o-linear-gradient(left, #f8b32f, #eaa92c);
  /* Opera 11.10+ */
  background-image: linear-gradient(to right, #f8b32f, #eaa92c);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#f8b32f', EndColorStr='#eaa92c');
  /* IE6ÐIE9 */
}

h1 a {
  color: #fff;
}

h2 {
  font-weight: normal;
  font-size: 1.5em;
  color: #333;
  margin: 10px 0;
}

h3 {
  font-weight: normal;
  font-size: 1.3em;
  color: #333;
  margin: 10px 0;
}

a {
  text-decoration: none;
  color: #f36c2c;
}

div.frontState {
  margin-top: 10px;
  margin-bottom: 5px;
}

div.frontState div.stateName {
  font-weight: bold;
  font-size: 1.2em;
}

div.frontState div.stateName span.areaCount {
  font-size: 0.9em;
  font-weight: normal;
  color: #999;
}

div.frontState div.areas {
  margin: 5px 0;
}

a.frontState {
  color: #333 !important;
}

body,
p,
div {
  font-family: arial, helvetica, sans-serif;
  font-size: 12px;
  line-height: 1.5;
}

p {
  margin: 10px 0;
}

div.whatNew {
  padding: 10px 20px;
}

.box {
  margin: 10px 20px;
}

div.rightBox {
  border: 1px solid #fff;
}
div.rightBoxTitle,
div.rightBoxTitleTop {
  font-family: "Oswald", arial, helvetica, sans-serif;
  background: #ccc;
  color: #fff;
  padding: 3px 20px;
  font-size: 1em;
}
div.rightBoxContent {
  padding: 10px;
  /* background: #f8f8f8; */
  background-color: #f8f8f8;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f8f8f8),
    to(#e1e1db)
  ); /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(
    top,
    #f8f8f8,
    #e1e1db
  ); /* Chrome 10+, Saf5.1+ */
  background-image: -moz-linear-gradient(top, #f8f8f8, #e1e1db); /* FF3.6 */
  background-image: -ms-linear-gradient(top, #f8f8f8, #e1e1db); /* IE10 */
  background-image: -o-linear-gradient(
    top,
    #f8f8f8,
    #e1e1db
  ); /* Opera 11.10+ */
  background-image: linear-gradient(to bottom, #f8f8f8, #e1e1db);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#f8f8f8', EndColorStr='#e1e1db'); /* IE6ÐIE9 */
}
/* Forecast */
div.forecast {
  width: 200px;
  float: left;
}
div.forecast div.icon {
  width: 80px;
  float: left;
}
div.forecast div.icon div.day {
  font-size: 0.8em;
  color: #999;
  text-transform: uppercase;
}
div.forecast div.icon div.symbol {
  padding: 2px;
}
div.forecast div.detail {
  width: 100px;
  float: left;
}
div.forecast div.desc {
  font-size: 0.8em;
  color: #999;
}
div.forecast div.data {
  font-size: 1.7em;
  padding: 3px 0 3px 0;
}
div.forecast div.dataSmall {
  font-size: 1em;
  padding: 3px 0 3px 0;
}

div.forecastSide {
  width: 110px;
  float: left;
}
div.forecastSide div.icon {
  width: 40px;
  float: left;
}
div.forecastSide div.icon div.day {
  font-size: 0.8em;
  padding-left: 3px;
  color: #999;
  text-transform: uppercase;
}
div.forecastSide div.icon div.symbol {
  padding: 2px;
  margin-top: 8px;
}
div.forecastSide div.detail {
  width: 60px;
  float: left;
  margin-top: 23px;
  margin-left: 10px;
}
div.forecastSide div.dataSmall {
  font-size: 0.8em;
  color: #666;
}

div.contentFull {
  background: #fff;
}

div.stateAreas {
  column-count: 2;
}

div.stateAreas h2 {
  font-weight: bold;
  font-size: 1.2em;
  color: #666;
  margin: 0 0 5px 0;
}

div.stateAreaSection {
  margin-bottom: 10px;
  break-inside: avoid;
}

div.stateAreaSection div {
  padding: 2px 0;
}

div#areaForecast {
  margin: 20px 5px 5px 5px;
}

div#hourlyForecast {
  margin: 20px 5px 5px 5px;
}

#hourlies {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 15px 10px 0;
  border: 1px #ccc solid;
  padding: 5px 0;
  overflow: auto;
}

.hourlyCell {
  display: flex;
  flex: 0 0 5em;
  flex-direction: column;
  align-items: center;
}

.hourly__dow {
  font-size: .8em;
  border-bottom: 1px solid #ccc;
  margin-bottom: 4px;
  height: 1.5em;
}

.hourly__accum {
  font-size: .8em;
}

.hourly__wind {
  font-size: .8em;
}

.hourlyTime {
  font-size: .8em;
}

.hourlySymbol {
  margin: 0;
  padding: 2px 0;
}

.hourlyTemp {
  font-size: 1.2em;
}

.hourlyPrecip {
  font-size: 1em;
  color: #666;
}

.daily {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.daily-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
}

.daily-row:nth-child(odd) {
  background-color: #f8f8f8;
}

.daily-row__day {
  /*flex: 0 0 8.5em;*/
  flex: 1;
}

.daily-row__day__dow {
  text-transform: uppercase;
  color: #f36c2c;
  font-weight: bold;
}
.daily-row__day__date {
  font-size: 0.8em;
  color: #999;
}

.daily-row__accum {
  flex: 0 0 3em;
  text-align: center;
}

/*
.daily-row__sp {
  display: flex;
  flex: 0 0 7em;
  flex-direction: row;
  justify-content: center;
}
*/

.daily-row__sp {
  display: flex;
  flex: 0 0 9em;
  /*flex: 1;*/
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.daily-row__sp__precip {
  flex: 0 0 3em;
  text-align: center;
  color: #666;
}



.daily-row__sp__symbol { 
  flex: 0 0 3em;
}

.daily-row__sp__accum { 
  flex: 0 0 3em;
  color: #666;
  font-size: 0.9em;
}
/*
.dailyFlexPrecip { 
  margin-left: 10px; 
}
*/
/*
.dailyFlexPrecipAccum { 
  margin-right: 10px; 
}
*/

.daily-row__temp {
  display: flex;
  flex: 0 0 10em;
  justify-content: flex-end;
  align-items: center;
}

.daily-row__temp__hl {
  display: flex;
  flex: 0;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 1px solid #ccc;
}
.daily-row__temp__hl__high { 
  font-size: 1.2em;
  margin-right: 10px;
}
.daily-row__temp__hl__low { 
  font-size: 1.2em;
  color: #999;

}

.daily-row__wind {
  display: flex;
  flex: 0 0 4em;
  justify-content: flex-end;
}

.daily-row__wind__sustained {
  font-size: 0.9em;
  color: #666;
}

.daily-row__details { 
  display: flex;
  flex: 1;
  justify-content: right;
  display: none;
}

.daily-row__details__conditions {
  flex: 1;
  text-align: center;
}

.mapContainer {
  margin: 50px;
  display: flex;
  justify-content: space-around;
}

.mapInner {
  border-radius: 20px;
  overflow: hidden;
}


@media only screen and (min-width: 600px) {

  .contentwrap {
    display: grid;
    grid-template-columns: 1fr 360px;
    grid-template-areas: "content side";
    grid-gap: 0px;
    max-width: 1024px;
    min-width: 360px;
    margin: 10px auto;
  }

  div.content {
    grid-area: content;
    background-color: #fff;
    border-left: 1px solid #ccc;
    max-width: 1024px;
    padding-bottom: 20px;
  }
  
  div.side {
    grid-area: side;
    background-color: #fff;
    border-left: 1px solid #ccc;
    display: block;
  }

  /*
  .contentwrap {
    grid-template-columns: 1fr 360px;
    grid-template-areas: "content side";
  }
  */
  /*
  div.side {
    display: block;
  }
  */
  
  div.stateAreas {
    column-count: 4;
  }

  div#areaForecast .tempHigh {
    display: inline;
    font-size: 1.4em;
  }
  div#areaForecast .tempLow {
    display: inline;
    font-size: 1.4em;
  }

  div#areaForecast .precipBySymbol {
    font-size: 1em;
  }

  div#areaForecast .dailyConditions {
    font-size: 1em;
  }

  div#areaForecast {
    font-size: 1em;
  }

  h1 { font-size: 1.4em; }
  h1.top { font-size: 1.6em; }

  div.rightBoxTitleTop {
    font-size: 1.4em;
  }

  .daily-row__day {
    flex: 0 0 12em;
  }
  
  .daily-row__sp {
    flex: 0 0 10em;
  }
  
  .daily-row__temp {
    flex: 0 0 10em;
    align-items: center;
    justify-content: center;
  }

  .daily-row__temp__hl {
    flex: 0 1;
  }

  .daily-row__details { 
    display: flex;
    font-size: .8em;
    padding: 5px;
    /*flex-grow: 2;*/
  }

  .footerLinks {
    text-align: right;
  }
}
